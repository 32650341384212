<template>
  <div style="height: 100%">
    <slot></slot>
  </div>
</template>

<style scoped>
html,
body {
  background: #f5f8fa;
  height: 100%;
}
</style>
